<template>
    <div :style="isMobile?'':'display: flex;    justify-content: center;'">
        <!-- margin-left: 12.5rem; -->
        <span v-if="isFirstTime" style="text-align: center;">
            <!-- <p style='white-space: pre-wrap;' v-html="policy"></p> -->
            <div style="width:100%">
                <div style="max-width:35rem;line-height: 1.8rem;    margin-top: 2rem;padding:0 1rem">
                    <p style="text-align: left;">尊敬的女士/先生:</p><br>
    <p style="text-align: left;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;欢迎您参与此次小调查活动。您的看法对我们非常重要，希望您能与我们分享您最真实的想法！若某问题您觉得难以给出适当的回答，请尽可能选择最为接近的答案。提交问卷代表您同意并授权我们将您填写的信息用来研究目的。请您放心，您所提供的答案以及您的个人资料都将做严格的保密处理。</p><br>
    <p style="text-align: left;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;感谢您的支持!</p>
                </div>
                <!-- <div><el-image style="" src="https://hfweb.fusewise-info.com/SurveyService/UploadBCSFiles/ysxy.png" fit="cover"></el-image></div> -->
            </div>
            <!-- <div><el-checkbox v-model="iKnow">我已知晓用户隐私协议</el-checkbox></div> -->
            <div style="margin-top:1rem;">
                <el-button @click="onAgree" type="primary">同意并继续</el-button>
                <el-button type="danger" @click="dialogVisible=true">不同意关闭</el-button>
            </div>
            <div v-if="curProjectQuestions.length==0" style="color:red;margin-top:1rem;">{{yitijiao}}</div>
        </span>
        <span v-else>
            <div v-if="!isOver" class="mod-menu-home app-container" style="text-align:center;flex-direction: column;align-items: center;flex-wrap: wrap;">
                <!-- style="width:800px;" -->
                <div style="text-align: center;max-width: 50rem;">
                    <div v-if="statusId !== '-1'" style="text-align:center;font-weight: bolder; padding: 1.875rem;">
                        <!-- <span v-if="curPart != '第二部分'">您好，我们邀请您参加一个小调查，希望您能与我们分享您最真实的想法!</span> -->
                    </div>
                    <div v-else  style="text-align:center;font-weight: bolder;margin-top:1.875rem;;padding-bottom:1.875rem;;">您已提交问卷，谢谢！</div>            


                    <div v-if="statusId === '9'" style="text-align: center;color:red;">未上线，不可作答</div>

                
                    <div style="padding: 0.625rem 0.625rem;" v-if="curQuestion.answers.filter(item => item.show === true).length>0 || curQuestion.typeId == 3 || curQuestion.typeId == 6 || curQuestion.typeId == 7">
                        <div style="display:flex;">
                            <div style="font-weight: bold;text-align: left; margin-left: 0.5rem;">
                                {{ indexQuestion+1 }}、{{ curQuestion.name }}
                                <span style="color:gray;">{{curQuestion.typeId == 1?'[单选]' : curQuestion.typeId == 2 ? '[多选]': curQuestion.typeId == 3 ?'[填空]' :curQuestion.typeId == 4 ? '[矩阵]' :curQuestion.typeId == 5 ? '[排序]':''}}</span>
                            </div>
                            <div class="msg">{{ curQuestion.msg }}</div>
                        </div>      
                        <!-- 单选 -->
                        <div v-if="curQuestion.typeId == 1" style="display:flex;flex-direction: column;text-align: left;line-height: 4ch;padding:0 0.5rem;">
                            <div style="margin-left:1.075rem;;" v-for="(ans,indexa) in curQuestion.answers.filter(item => item.show === true)" :key="indexa">
                                <el-radio  v-model="ans.selected" :label="ans.aId" @change="onSingleItemChange(curQuestion,ans,indexQuestion)">
                                    <span class="all_label">{{ ans.answer }}</span>
                                </el-radio>
                                <span v-if="ans.fillOther">
                                    <el-input style="width:12.5rem;" :disabled="!ans.enableOther" placeholder="请输入"  v-model="ans.otherTextValue"  clearable></el-input>
                                </span>
                            </div>
                        </div>
                        <!-- 多选 -->
                        <div v-else-if="curQuestion.typeId == 2 || curQuestion.typeId == 5" style="display:flex;flex-wrap: wrap;line-height: 4ch;">
                            <div style="text-align: left;">
                                <div style="margin-left:0.5rem;" v-for="(ans,indexa) in curQuestion.answers.filter(item => item.show === true)" :key="indexa">
                                    <el-checkbox  v-model="ans.selected" :disabled="ans.disabled" :label="ans.aId" @change="onMultiItemChange(curQuestion,ans,indexQuestion)">
                                        {{ ans.answer }}
                                    </el-checkbox>
                                    <span v-if="ans.fillOther">
                                        <el-input style="width:12.5rem;" :disabled="!ans.enableOther" placeholder="请输入"  v-model="ans.otherTextValue"  clearable></el-input>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- 填空 -->
                        <div v-else-if="curQuestion.typeId == 3" style="text-align: left;">
                            <el-input  style="width:25rem;margin-left:1.525rem;" placeholder="请输入"  v-model="curQuestion.seleValue"  clearable @change="onTextChange(curQuestion,true)"></el-input>
                        </div>
                        <!-- 省份城市 -->
                        <div v-else-if="curQuestion.typeId == 6" style="text-align: left;padding:0 0.5rem;">
                            <el-cascader  style="margin-left:1.875rem;" :options="provinceCityList" filterable @change="(val)=>{onDDLChange(curQuestion, val)}"></el-cascader>
                        </div>
                        <!-- 打分 -->
                        <div v-else-if="curQuestion.typeId == 7">
                            <div style="display:flex;">
                                <el-rate  style="margin-left:1.875rem;" show-score v-model="curQuestion.seleValue" :max="curQuestion.maxNum" @change="onTextChange(curQuestion,false)"></el-rate><div style="margin-left:0.1875rem;">分</div>
                            </div>
                        </div>
                        <!-- 矩阵 -->
                        <div v-else-if="curQuestion.typeId == 4" style="display: flex;justify-content: center;padding:0 0.5rem;">
                            <div style="width: 100%;">
                                <table class="my-table">
                                    <tr style="font-weight: bold;">
                                        <td style="min-width:6.25rem;"></td>
                                        <td style="padding:0 0.4375rem;min-width:1.8rem;text-align:center;" v-for="(itemcol,indexcol) in curQuestion.queCols" :key="indexcol">{{ itemcol.colName }}</td>
                                    </tr>
                                    <tr v-for="(itemrow,indexrow) in curQuestion.answers.filter(item => item.show === true)" :key="indexrow">
                                        <td style="max-width:16.25rem;">{{ itemrow.answer }}</td>
                                        <td style="text-align: center;" v-for="(itemcol,indexcol) in itemrow.subAnswer" :key="indexcol">
                                            <el-radio v-model="itemcol.selected" label=" " @change="onJZItemChange(curQuestion,itemrow,itemcol,indexQuestion)"></el-radio>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div v-else style="margin-left:1.875rem;;" v-for="(ans,indexa) in curQuestion.answers" :key="indexa">
                            {{ indexa+1 }}.{{ ans.answer }}
                        </div>
                    </div>
                               
                </div>

                <div v-if="statusId !== '-1'" style="text-align: center;padding:1.25rem 0rem;">
                    <el-button type="primary" v-if="indexQuestion>0 && !oneQuestionPerPage"
                    :disabled="error || !curProjectQuestions || curProjectQuestions.length==0 || statusId === '9'" round @click="onPrev">
                    上一题
                    </el-button>
                    <el-button type="primary" 
                    :disabled="error || !curProjectQuestions || curProjectQuestions.length==0 || statusId === '9'" round @click="onSubmit">
                    <!-- {{!existTwoParts || curPart !='第一部分' ? '提交' : '下一题'}} -->
                    {{ btnLabel }}
                    </el-button>
                </div>
            </div>
            <div v-else>
                <div style="text-align: center;margin-top:1.875rem;;"><p v-html="finishMsg"></p></div>
            </div>
        </span>

        <el-dialog
            title="提示"
            v-model="dialogVisible" width="30%"  
            :modal-append-to-body='false'
            :close-on-click-modal='false'
            v-if="dialogVisible"
            append-to-body>
            <span>确认关闭当前页面, 是否继续?</span><br>
            <div style="margin-top:1rem;text-align: center;">
                <span slot="footer" class="dialog-footer">                    
                    <el-button type="primary" @click="onCloseWindow">确 定</el-button>
                    <el-button type="danger" @click="dialogVisible = false">取 消</el-button>
                </span>
            </div>
        </el-dialog>
        <!-- <p v-html="aaa"></p> -->
    </div>
</template>
<script>
import { mapGetters } from "vuex"; 
import { sortBykey, guid } from "@/utils/tools";
import { getCurrentTime } from "@/utils/time";
import { SaveAnswers } from "@/api/survey";
import {_isMobile} from  "@/utils/tools";

export default {
    components: {
        // WangEditor,
    },
    // props: ['projectId'],
    computed: {...mapGetters(['projectQuestions','provinceCityList'])},
    data() {
        return {
            isOver: false,
            allSelectValue: [],
            texts: ['非常多','较多','多','较少','非常少'],
            colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
            projectId: '',
            rxsign: '',
            isTest:false,
            answerUserId: '',
            sign: '',
            enterTime: '',
            submitTime: '',
            error: false,
            statusId: '',
            curProjectQuestions:[],
            curQuestion:{
                answers:[]
            },
            indexQuestion:0,
            btnLabel:'下一题',
            oneQuestionPerPage:true,
            maxMedia:0,
            maxMedia_MT:0,
            maxMedia_YX:0,
            maxMedia_NRFG:0,
            maxMedia_NRLX:0,

            curVisibleMediaCnt:0,

            curVisibleMediaCnt_MT:0,
            curVisibleMediaCnt_YX:0,
            curVisibleMediaCnt_NRFG:0,
            curVisibleMediaCnt_NRLX:0,

            isFirstTime:true,
            policy:'<table><tr><td style="font-weight:bold;">隐私政策</td></tr></table><br>巴拉巴拉巴拉巴拉',
            dialogVisible:false,
            iKnow:false,
            agent:'',
            // partOne:[],
            // partTwo:[]
            existTwoParts: false,
            curPart: '第一部分',
            haveQuota:false,
            finishMsg: '',
            isMobile: false,
            // aaa:'aaa</br>bbb'
            yitijiao:''
        };
    },
    async mounted() {
        // console.log('kao')
        this.isMobile = _isMobile()
        // console.log('aaa',this.$router,this.$route)
        this.projectId = this.$route.params.projectId        
        this.rxsign = this.$route.params.rxsign        
        var lstr = this.rxsign.substring(0,4)
        var rstr = this.rxsign.substring(4)
        //console.log(lstr,rstr)
        if (this.rxsign.length==40 && lstr == 'tset'){
            this.isTest = true
            this.rxsign = rstr
        }
        // this.answerUserId = this.$route.params.userId
        var localCode
        if (this.isTest){
            localCode = 'test-'+guid()            
        } else{
            localCode = localStorage.getItem('LcUCode')
            if (!localCode){
                localCode = guid()
            }
            localStorage.setItem('LcUCode',localCode)
        }
        this.answerUserId = localCode
        // console.log('aaaaa',this.$route.params)
        this.sign = this.$route.params.sign
        this.agent = this.$route.params.agent
        if (!this.projectId || !this.rxsign || !this.answerUserId || !this.sign || !this.agent) {// 
            // console.log('无效访问！')
            this.$message.error("无效访问！");
            this.error = true;
            return;
        }
        // 
        // if (!this.answerUserId || !this.answerUserId) {
        //     this.error = true;
        //     return;
        // }
        // if (!this.sign || !this.sign) {
        //     this.error = true;
        //     return;
        // }
        // console.log('this.agent',this.agent)
        var data = {userId:this.answerUserId, projectId: this.projectId,agent:this.agent}
        // console.log('data1',data)
        this.enterTime = getCurrentTime();
        await this.$store.dispatch('survey/GetProjectQuestions',data).then(()=>{ 
            this.statusId = this.projectQuestions.projInfo.statusId
            this.rxsign = this.projectQuestions.projInfo.rxsign
            this.oneQuestionPerPage = true;//this.projectQuestions.projInfo.oneQuestionPerPage
            this.maxMedia = this.projectQuestions.projInfo.maxMedia

            this.maxMedia_MT = this.projectQuestions.projInfo.maxMedia_MT
            this.maxMedia_YX = this.projectQuestions.projInfo.maxMedia_YX
            this.maxMedia_NRFG = this.projectQuestions.projInfo.maxMedia_NRFG
            this.maxMedia_NRLX = this.projectQuestions.projInfo.maxMedia_NRLX

            this.curProjectQuestions = this.projectQuestions.pqList

            if (this.curProjectQuestions.length==0){
                this.yitijiao = '当前问卷已提交，不可重复作答'
            }
            this.indexQuestion = 0
            this.getCurQuestion(true)
         })
        await this.$store.dispatch('survey/GetProvinceCityList',{userId:1, typeId:0 }).then(()=>{  })
    },
    methods: {
        onAgree(){
            // if (this.iKnow == false){
            //     this.$message.warning("请勾选'我已知晓用户隐私协议'");
            //     return;
            // }
            this.isFirstTime=false
        },
        getCurQuestion(next){
            var tmpP={}
            tmpP = this.curProjectQuestions[this.indexQuestion]
            // console.log('tmpP',tmpP,this.getAnswerCnt(tmpP))
            while((tmpP.visible == false || this.getAnswerCnt(tmpP)==0) && ((next && this.indexQuestion<this.curProjectQuestions.length) || (!next && this.indexQuestion>=0))){
                if (next){
                    this.indexQuestion++
                }else{
                    this.indexQuestion--
                }
                tmpP = this.curProjectQuestions[this.indexQuestion]
            }
            // if (tmpP.visible){
                this.curQuestion = tmpP
            // }else{
            //     this.curQuestion = null
            // }



            // var tmpList = this.curProjectQuestions.filter(item => item.visible === true)// && item.haveQuota === this.haveQuota
            // if (this.indexQuestion==tmpList.length){
            //     this.indexQuestion--
            // }
            // if (this.indexQuestion<0){
            //     this.indexQuestion = 0
            // }
            // if (tmpList){
            //     this.curQuestion = tmpList[this.indexQuestion];
            // }
            // // console.log('bbbcc',this.curQuestion)

        },
        onPrev(){
            this.indexQuestion--
            this.getCurQuestion(false)
        },
        onSingleItemChange(q,a,indexq){
            // 选择其他，“其他”输入框可输入内容
            q.answers.forEach(ele => {
                if (ele.aId !== a.aId){
                    ele.selected = false
                    this.setAnswerLogic(q, ele, 0,indexq,ele.selected)
                }
            });

            if (a.fillOther) {
                a.enableOther = ! a.enableOther
            }
            q.msg = ''
            if (q.effectQuestionIds && q.effectQuestionIds.length>0){
                q.effectQuestionIds.forEach(ele => {
                    this.setQuestionLogic(ele,q.cateName)
                });
            }
            console.log('3')
            this.setAnswerLogic(q, a, 0,indexq,a.selected)
          },
        setAnswerLogic(ques,itemrow,itemcol,indexq,selected){    
            if (selected !== false) {
                selected = true
            }        
            this.curProjectQuestions.forEach(function(ele1,index) {
                // var ans = ele1.an
                if (index > indexq) {
                    ele1.answers.forEach(ele2 => {
                        if (ele2.condition !== '') {
                            // console.log(ques.qId + ':' + itemrow.aId + '-' + itemcol, ele2.condition, selected)
                            if (ques.qId + ':' + itemrow.aId + '-' + itemcol == ele2.condition) {// "1320:6440-6442"
                                ele2.show = selected
                            }
                        }
                    });
                }
                // console.log(ele1,index)
            });
        },
        onMultiItemChange(q,a,indexq) {
            // console.log('aa')
            // 设置选项选满额后，其他项不可选
            if (q.maxNum < 999){
                var selCnt=0
                q.answers.forEach(ele => {
                    if (ele.selected){
                        selCnt ++
                    }
                });
                // console.log('aa', selCnt, q.maxNum)
                if (selCnt === q.maxNum){
                    q.answers.forEach(ele => {
                        if (!ele.selected) {
                            ele.disabled = true
                        }
                    })
                } else {
                    q.answers.forEach(ele => {
                        ele.disabled = false
                    })
                }
            }
            // 选择其他，“其他”输入框可输入内容
            if (a.fillOther) {
                a.enableOther = ! a.enableOther
            }
            // 排序题
            if (q.typeId === 5){// 排序题
                // console.log('aaa',q.answers)
                var tmpas = q.answers
                var selList = q.answers.filter(item=>item.selOrderId<999)
                // console.log('aaa',tmpas)
                tmpas.forEach(ele => {
                    if (ele.aId === a.aId){
                        if (a.selected){
                            ele.selOrderId = selList.length + 1
                        } else {
                            ele.selOrderId = 999
                        }
                    }
                });
                q.answers = sortBykey(tmpas,'selOrderId')
            }
            // 设置题目逻辑
            // q.answers.forEach(ans => {
            // console.log('aaaaa',q.haveLogicCondition,a.sonQuestionIds.length)


            if (q.effectQuestionIds && q.effectQuestionIds.length>0){
                q.effectQuestionIds.forEach(ele => {
                    this.setQuestionLogic(ele,q.cateName)
                });
            }
            q.msg = ''  
            // });
            this.setAnswerLogic(q, a, 0,indexq,a.selected)
            // console.log(q,a)
        },
        onTextChange(q,isText){            
            if (isText && q.seleValue.trim()!==''){
                q.msg = ''
            }
            if (!isText && q.seleValue){
                q.msg = ''
            }
        },
        onDDLChange(q, val){
            // console.log('aaa', q, val)
            q.seleValue = val[0] + '/' + val[1]
            if (q.seleValue.trim()!==''){
                q.msg = ''
            }
        },
        jiaJIanVisibleMediaCnt(cateName,addNum){
            if (cateName=='媒体触点(评估)' || cateName=='媒体触点(规划)'){                
                this.curVisibleMediaCnt_MT = this.curVisibleMediaCnt_MT + addNum
                console.log('jiajian',cateName,this.curVisibleMediaCnt_MT)
            }
            if (cateName=='营销触点(评估)' || cateName=='营销触点(规划)'){
                this.curVisibleMediaCnt_YX = this.curVisibleMediaCnt_YX + addNum
                console.log('jiajian',cateName,this.curVisibleMediaCnt_YX)
            }
            if (cateName=='内容类型'){
                this.curVisibleMediaCnt_NRLX = this.curVisibleMediaCnt_NRLX + addNum
                console.log('jiajian',cateName,this.curVisibleMediaCnt_NRLX)
            }
            if (cateName=='内容风格'){
                this.curVisibleMediaCnt_NRFG = this.curVisibleMediaCnt_NRFG + addNum
                console.log('jiajian',cateName,this.curVisibleMediaCnt_NRFG)
            }
        },
        plusVisibleMediaCnt(pa,cateName,existBMZ,existMZ,searchQ){
            var mm=0
            var vCnt=0
            if (cateName=='媒体触点(评估)' || cateName=='媒体触点(规划)'){
                mm = this.maxMedia_MT
                vCnt = this.curVisibleMediaCnt_MT
            }
            if (cateName=='营销触点(评估)' || cateName=='营销触点(规划)'){
                mm = this.maxMedia_YX
                vCnt = this.curVisibleMediaCnt_YX
            }            
            if (cateName=='内容类型'){
                mm = this.maxMedia_NRLX
                vCnt = this.curVisibleMediaCnt_NRLX
            }
            if (cateName=='内容风格'){
                mm = this.maxMedia_NRFG
                vCnt = this.curVisibleMediaCnt_NRFG
            }
            console.log('plusVisibleMediaCnt',cateName,mm,vCnt,pa.isAnd,existBMZ,existMZ)
            if (mm == 0 || (vCnt<mm && mm>0)){
                if (pa.isAnd && !existBMZ){
                    searchQ[0].visible = true
                    // this.curVisibleMediaCnt++
                    this.jiaJIanVisibleMediaCnt(cateName,1)
                } else if (!pa.isAnd && existMZ){
                    searchQ[0].visible = true
                    // this.curVisibleMediaCnt++
                    this.jiaJIanVisibleMediaCnt(cateName,1)
                }
            }
        },
        // 设置题目逻辑
        setQuestionLogic(qId,cateName){
            var searchQ = this.curProjectQuestions.filter(item => item.qId === qId)
            var logic = searchQ[0].logic
            // console.log('aaa', searchQ,logic)
            if (searchQ[0].visible){
                // this.curVisibleMediaCnt--
                this.jiaJIanVisibleMediaCnt(cateName,-1)
            }
            // console.log('1')
            searchQ[0].visible = false
            if (searchQ) {
                var pa =  searchQ[0].parentAnswer // 所有前置的答案列表
                // console.log('pa',pa)
                var existBMZ = false // 存在不满足条件
                var existMZ = false  // 存在满足条件
                
                pa.parentItem.forEach(ele2 => {
                    var searchPQ = this.curProjectQuestions.filter(item => item.qId === ele2.qId && item.visible == true)[0]
                    // console.log('ppa',searchPQ,searchPQ.typeId)
                    // console.log('2',searchPQ)
                    if (searchPQ){
                        if (searchPQ.typeId === 1) {
                            var anlist = searchPQ.answers.filter(item => item.aId*1 === ele2.aId*1)
                            // console.log('pp',anlist)
                            if (anlist && anlist.length===1){
                                if (anlist[0].selected) {
                                    // console.log('ppa')
                                    existMZ = true
                                } else {
                                    existBMZ = true
                                    // console.log('ppb')
                                }
                            }
                        } else if (searchPQ.typeId === 2 || searchPQ.typeId === 5) {
                            var anlist = searchPQ.answers.filter(item => item.aId*1 === ele2.aId*1)
                            if (anlist && anlist.length===1){
                                if (anlist[0].selected) {
                                    existMZ = true
                                } else {
                                    existBMZ = true
                                }
                            }
                        } else if (searchPQ.typeId === 4){
                            var anlist = searchPQ.answers.filter(item => item.aId*1 === ele2.aId*1)      
                            if (anlist && anlist.length===1){
                                // console.log('111',anlist)     
                                var collist = anlist[0].subAnswer.filter(item => item.aId === ele2.aId + '-' + ele2.colId)
                                // console.log('222',collist)
                                if (collist && collist.length===1){
                                    if (collist[0].selected) {
                                        existMZ = true
                                    } else {
                                        existBMZ = true
                                    }
                                }
                            }
                        }
                    }
                });
                
                // console.log('bbb', searchQ,logic)
                if (logic == '或'){
                    pa.isAnd = false
                }else{
                    pa.isAnd = true
                }
                this.plusVisibleMediaCnt(pa,cateName,existBMZ,existMZ,searchQ)
                // if (this.maxMedia == 0 || (this.curVisibleMediaCnt<this.maxMedia && this.maxMedia>0)){
                //     if (pa.isAnd && !existBMZ){
                //         searchQ[0].visible = true
                //         this.curVisibleMediaCnt++
                //     } else if (!pa.isAnd && existMZ){
                //         searchQ[0].visible = true
                //         this.curVisibleMediaCnt++
                //     }
                // }
            }
        },

        // 矩阵中的选项变化
        onJZItemChange(q,item,subItem,indexq){
            // console.log('aa')
            // subItem.selected = !subItem.selected
            if (item.subTypeId === '1') {
                // console.log('bb',item)
                if (subItem.selected) {
                    item.subAnswer.forEach(element => {
                        if (element.aId !== subItem.aId) {
                            if (element.selected){
                                element.selected = false                                
                            }                            
                        }
                        // console.log('11', element.selected)
                        // this.setAnswerLogic(q, item, subItem.aId.split('-')[1],indexq,element.selected)
                        this.setAnswerLogic(q, item, element.aId.split('-')[1],indexq,element.selected)
                    });
                }else{
                    // 单击按钮不会执行不选中得情形
                    // console.log('22subItem.colIndex', subItem)
                    // this.setAnswerLogic(q, item, subItem.aId.split('-')[1],indexq,subItem.selected)
                }
                if (item.effectQuestionIds && item.effectQuestionIds.length>0){
                    item.effectQuestionIds.forEach(ele => {
                        // console.log(ele)
                        this.setQuestionLogic(ele,q.cateName)
                    });
                }
            }
            q.msg = ''
        },
        onCheck(isPartOne){
            var isRight = true

            // this.allSelectValue=[]
            this.curProjectQuestions.forEach(eleq => {
                if (eleq.visible){// && ((isPartOne && eleq.haveQuota === true) || !isPartOne)
                    if (eleq.typeId === 1 || eleq.typeId === 2 || eleq.typeId === 5){ //  单选 多选 或 排序
                        var min,max
                        if (eleq.typeId === 1){
                            min = 1
                            max = 1
                        }else{
                            min = 1
                            max = eleq.maxNum
                        }
                        var selCnt = 0
                        var fillO = false
                        eleq.answers.forEach(elea => {
                            if (elea.selected && elea.show){                                
                                selCnt++
                                if (elea.fillOther && !elea.otherTextValue){
                                    fillO = true
                                }
                                if (elea.fillOther){
                                    this.allSelectValue.push({qId:eleq.qId,aId:elea.aId,colId:0,otherText:elea.otherTextValue,selOrderId:elea.selOrderId})
                                }else{
                                    this.allSelectValue.push({qId:eleq.qId,aId:elea.aId,colId:0,otherText:'',selOrderId:elea.selOrderId})
                                }
                            }
                        });
                        // console.log(selCnt,min,max,fillO)
                        // if (selCnt < min || selCnt>max || fillO){
                        console.log(selCnt , max ,fillO)
                        if ((selCnt !== max && max<999) || fillO || selCnt == 0){
                            eleq.msg = '请按要求回答'
                            isRight = false
                        }else{
                            eleq.msg = ''
                        }
                    }
                    if (eleq.typeId === 3 || eleq.typeId === 6 || eleq.typeId === 7){ //  填空 / 城市 / 打分
                        if (!eleq.seleValue){
                            eleq.msg = '请按要求回答'
                            isRight = false
                        }
                        this.allSelectValue.push({qId:eleq.qId,aId:0,colId:0,otherText:eleq.seleValue,selOrderId:999})
                    }
                    if (eleq.typeId === 4){ //  矩阵
                        var isjzRight=true
                        eleq.answers.forEach(elea => {
                            var selCnt = 0
                            var fillO = false
                            if (elea.show) {                            
                                elea.subAnswer.forEach(eleasub => {
                                    if (eleasub.selected){
                                        selCnt++
                                        if (eleasub.fillOther && !eleasub.otherTextValue){
                                            fillO = true
                                        }
                                        if (eleasub.fillOther){
                                            this.allSelectValue.push({qId:eleq.qId,aId:elea.aId,colId:eleasub.aId.split('-')[1],otherText:eleasub.otherTextValue,selOrderId:999})
                                        }else{
                                            this.allSelectValue.push({qId:eleq.qId,aId:elea.aId,colId:eleasub.aId.split('-')[1],otherText:'',selOrderId:999})
                                        }
                                    }
                                });
                                if (selCnt < 1 || fillO){                                
                                    isjzRight = false
                                    isRight = false
                                }
                            }
                        })
                        if (!isjzRight){
                            eleq.msg = '请按要求回答'
                        }else{
                            eleq.msg = ''
                        }
                    }
                }                
            });
            return isRight
        },
        onCheckOneQuestion(isPartOne){
            var isRight = true
            // this.allSelectValue=[]
            var eleq = this.curQuestion
                if (eleq.visible){// && ((isPartOne && eleq.haveQuota === true) || !isPartOne)
                    if (eleq.typeId === 1 || eleq.typeId === 2 || eleq.typeId === 5){ //  单选 多选 或 排序
                        var min,max
                        if (eleq.typeId === 1){
                            min = 1
                            max = 1
                        }else{
                            min = 1
                            max = eleq.maxNum
                        }
                        var selCnt = 0
                        var fillO = false
                        eleq.answers.forEach(elea => {
                            if (elea.selected && elea.show){                                
                                selCnt++
                                if (elea.fillOther && !elea.otherTextValue){
                                    fillO = true
                                }
                                if (elea.fillOther){
                                    this.allSelectValue.push({qId:eleq.qId,aId:elea.aId,colId:0,otherText:elea.otherTextValue,selOrderId:elea.selOrderId})
                                }else{
                                    this.allSelectValue.push({qId:eleq.qId,aId:elea.aId,colId:0,otherText:'',selOrderId:elea.selOrderId})
                                }
                            }
                        });
                        // console.log(selCnt,min,max,fillO)
                        // if (selCnt < min || selCnt>max || fillO){
                        console.log(selCnt , max ,fillO)
                        if ((selCnt !== max && max<999) || fillO || selCnt == 0){
                            eleq.msg = '请按要求回答'
                            isRight = false
                        }else{
                            eleq.msg = ''
                        }
                    }
                    if (eleq.typeId === 3 || eleq.typeId === 6 || eleq.typeId === 7){ //  填空 / 城市 / 打分
                        if (!eleq.seleValue){
                            eleq.msg = '请按要求回答'
                            isRight = false
                        }
                        this.allSelectValue.push({qId:eleq.qId,aId:0,colId:0,otherText:eleq.seleValue,selOrderId:999})
                    }
                    if (eleq.typeId === 4){ //  矩阵
                        var isjzRight=true
                        eleq.answers.forEach(elea => {
                            var selCnt = 0
                            var fillO = false
                            if (elea.show) {                            
                                elea.subAnswer.forEach(eleasub => {
                                    if (eleasub.selected){
                                        selCnt++
                                        if (eleasub.fillOther && !eleasub.otherTextValue){
                                            fillO = true
                                        }
                                        if (eleasub.fillOther){
                                            this.allSelectValue.push({qId:eleq.qId,aId:elea.aId,colId:eleasub.aId.split('-')[1],otherText:eleasub.otherTextValue,selOrderId:999})
                                        }else{
                                            this.allSelectValue.push({qId:eleq.qId,aId:elea.aId,colId:eleasub.aId.split('-')[1],otherText:'',selOrderId:999})
                                        }
                                    }
                                });
                                if (selCnt < 1 || fillO){                                
                                    isjzRight = false
                                    isRight = false
                                }
                            }
                        })
                        if (!isjzRight){
                            eleq.msg = '请按要求回答'
                        }else{
                            eleq.msg = ''
                        }
                    }
                }                
            // });
            // console.log('bbb',eleq.msg,this.curQuestion.msg)
            return isRight
        },
        // onCheckQuota(){            
        //     var cando = true
        //     var partOne = this.curProjectQuestions.filter(item => item.haveQuota === true)
        //     partOne.forEach(eleq => {
        //         // console.log('bb', eleq)
        //         eleq.answers.forEach(elea => {
        //             // console.log('elea', elea)
        //             if (elea.selected) {
        //                 if (elea.remain <= 0){
        //                     cando = false
        //                 }
        //             }
        //         });
        //     });
        //     return cando;
        // },
        onCheckQuota(q){            
            var cando = true
            if (q.haveQuota){
                q.answers.forEach(elea => {
                    if (elea.selected) {
                        if (elea.remain <= 0){
                            cando = false
                        }
                    }
                });
            }            
            return cando;
        },

        // 返回 1：最后一题结束  2：下一题无有效答案结束  3：超出配额结束 4:未结束
        onCheckIsFinished(){ //查看是否有下一题或者是否下一题答案是空，即：都回答了未接触            
            var tmpIndex = this.indexQuestion
            if (!this.onCheckQuota(this.curProjectQuestions[tmpIndex])){
                return 3
            }
            tmpIndex++
            if (tmpIndex == this.curProjectQuestions.length){// 没有下一题了
                return 1
            }
            var tmpP={}
            tmpP = this.curProjectQuestions[tmpIndex]
            // console.log('tmpP',tmpP,this.getAnswerCnt(tmpP))            
            if (tmpP.visible && this.getAnswerCnt(tmpP)==0){
                return 2
            }

            while(tmpP && tmpP.visible == false && tmpIndex<this.curProjectQuestions.length){
                tmpIndex++
                tmpP = this.curProjectQuestions[tmpIndex]
                // console.log('6',tmpP)
            }
            if (tmpIndex>=this.curProjectQuestions.length){
                return 1
            }else{
                if (tmpP.visible && this.getAnswerCnt(tmpP)==0){
                    return 2
                }else{
                    return 4
                }
            }
        },

        getAnswerCnt(tmpP){
            var showCnt = 0
            if (tmpP.typeId === 1 || tmpP.typeId === 2 || tmpP.typeId === 4 || tmpP.typeId === 5){                
                tmpP.answers.forEach(eleCnt => {
                    if (eleCnt.show){
                        showCnt ++
                    }
                });
            }else{
                showCnt = 1// 只要大于0就行
            }
            return showCnt
        },

        async onSubmit(){
            var check;
            check = this.onCheckOneQuestion(false)

            var finishFlag = this.onCheckIsFinished() //1：最后一题结束  2：下一题无有效答案结束 3：超出配额结束 4:未结束
            console.log('finishFlag',finishFlag,this.curQuestion.msg)
            console.log('this.allSelectValue',this.allSelectValue)
            if (finishFlag == 4){// || (isFinished && this.curPart === '第一部分')
                if (this.curQuestion.msg === ''){
                    this.indexQuestion++
                    this.getCurQuestion(true)
                    return
                }
            }

            if (check){                                
                // if (this.existTwoParts && this.curPart === '第一部分') {
                    // this.onCheck(true)
                    // var canGoOn = this.onCheckQuota()
                if (finishFlag==2 || finishFlag == 3) {
                //     this.curPart = '第二部分'
                //     this.haveQuota = false
                //     return
                // } else {
                    this.submitTime = getCurrentTime();
                    var obj = {
                        userId: this.answerUserId,
                        projectId: this.projectId,
                        enterTime: this.enterTime,
                        submitTime: this.submitTime,
                        isAll: false,
                        agentId:this.agent,
                        userAnswer: this.allSelectValue}
                    // console.log('a1',obj)
                    await SaveAnswers({obj}).then((res)=>{
                        this.isOver = true;
                        // console.log('ret',res)
                        // window.location.href = 'http://fe-crowdsourcing-qa.cpc-static-upload.qutoutiao.net/jdRewardInfo?memberId=' + this.answerUserId + '&status=q&sign='+this.sign
                    })
                    this.finishMsg = '不符合样本条件，问卷结束，感谢您的参与！</br> </br> 您的参与码为：<span style="font-weight: bold;">'+this.rxsign.substring(this.rxsign.length - 6)+'-'+this.answerUserId.substring(this.answerUserId.length - 6)+'</span>'
                    
                    return
                }
                else{
                    // this.onCheck(false)
                    this.submitTime = getCurrentTime();
                    var obj = {
                        userId: this.answerUserId,
                        projectId: this.projectId,
                        enterTime: this.enterTime,
                        submitTime: this.submitTime,
                        isAll: true,
                        agentId:this.agent,
                        userAnswer: this.allSelectValue}
                    // console.log('a1',obj)
                    await SaveAnswers({obj}).then((res)=>{
                        // console.log('res',res)
                        this.finishMsg = '问卷已提交!感谢您的支持与配合!</br> </br> 您的参与码为：<span style="font-weight: bold;">'+this.rxsign.substring(this.rxsign.length - 6)+'-'+this.answerUserId.substring(this.answerUserId.length - 6)+'</span>'
                        console.log('ret',res)
                        // window.location.href = 'http://fe-crowdsourcing-qa.cpc-static-upload.qutoutiao.net/jdRewardInfo?memberId=' + this.answerUserId + '&status=c&sign='+this.sign
                        this.isOver = true;
                    })
                }                
            }
           
        },

        onCloseWindow(){
            console.log('aaa')
            // this.$confirm('确认关闭当前页面, 是否继续?', '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // }).then(()=>{
            //     console.log('guanbi')
                // window.close()
            //     window.parent.close()
            // })
            // window.open("","_self").close()  
            window.open("about:blank","_self").close()     
        }
    }
}
</script>
 
<style scoped lang="scss">
sapn{

word-break: normal;

display: block;

white-space: pre-wrap;

word-wrap: break-word;

overflow: hidden;

width: 20%;

}
.all_label{
    display: inline-grid;
    white-space: pre-line;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 20px;
}
.text {
      display: inline-grid;
      white-space: pre-line;
      word-wrap: break-word;
      overflow: hidden;
      line-height: 20px;
    }
    .el-checkbox__input {
	display: inline-grid;
    white-space: pre-line;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 20px;
 }
.msg{
    color: red;
    margin-left:0.5rem;
}

.require:before {
    content: '* ';
    color: red;
}
 
::v-deep .tableBB .el-input__inner {
    border: none !important;
}
 
.operation {
    width: 60%;
    text-align: right;
}
 
.header {
    padding: 1.5rem;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    font-size: 1.125rem;
    font-weight: bolder;
}
</style>
