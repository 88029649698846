<template>
    <div class="login-wrap">

        <!-- <div >
            <el-image style="height: 120px;width: 200px;margin-left: -30px;margin-top: -20px;" src="https://hfweb.fusewise-info.com/SurveyService/UploadBCSFiles/logo1.png" fit="contain"></el-image>
        </div>

        <div class="ms-login">
            <div class="ms-title">
                <div style="display: flex;justify-content: center;">
                    <div style="color:#F64843;margin-left:10px;">TEM</div> <div>接触点效益</div>
                </div>
                <div style="color:gray;font-size:20px;">智能管理系统</div>
            </div>

            <div class="ms-subtitle">{{title}}</div>
            <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                <el-form-item prop="username">
                    <el-input v-model="param.username" ref="username" placeholder="username">
                        <template #prepend>
                            <el-button icon="el-icon-user"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input
                        type="password"
                        ref="password"
                        placeholder="password"
                        v-model="param.password"
                        @keyup.enter="submitForm()"
                    >
                        <template #prepend>
                            <el-button icon="el-icon-lock"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <div class="l-end clearfix">
                    <label class="l-check">
                    <input :checked="isSavePassword" @change="onSavePassword" type="checkbox"/><span style="margin-left:10px;">记住密码</span>
                    </label>
                </div>
                <div class="login-btn">
                    <el-button type="primary" @click="submitForm()">登录</el-button>
                </div>
            </el-form>
        </div> -->
        
        <img src="../assets/img/qidezijing1.png" alt="启德紫荊" class="login-img">
        <div class="ms-login">
            <div class="login-container">
                <h1 class="login-container-chinese">接触点效益智能管理系统</h1>
                <h4 class="login-container-english" >Touchpoint Effectiveness Management System</h4>
            </div>
            <div style="display:flex;justify-content: center;">
                <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                    <el-form-item prop="username">
                        <el-input v-model="param.username" ref="username" placeholder="请输入用户名">
                            <template #prepend>
                                <el-button icon="el-icon-user"></el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input
                            type="password"
                            ref="password"
                            placeholder="请输入密码"
                            v-model="param.password"
                            @keyup.enter="submitForm()"
                        >
                            <template #prepend>
                                <el-button icon="el-icon-lock"></el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <div class="l-end clearfix">
                        <label class="l-check">
                        <input :checked="isSavePassword" @change="onSavePassword" type="checkbox"/><span style="margin-left:10px;">记住密码</span>
                        </label>
                    </div>
                    <div class="login-btn">
                        <el-button type="primary" @click="submitForm()">登录</el-button>
                    </div>
                </el-form>
                <!-- <el-form style="width:400px;" :model="param" :rules="rules" ref="login" label-width="0px" class="login-container-form">
                    <el-form-item prop="username">
                        <el-input v-model="param.username" ref="username" placeholder="请输入用户名">
                            <template #prepend>
                                <el-button icon="el-icon-user"></el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input
                            type="password"
                            ref="password"
                            placeholder="请输入密码"
                            v-model="param.password"
                            @keyup.enter="submitForm()"
                        >
                            <template #prepend>
                                <el-button icon="el-icon-lock"></el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <div class="l-end clearfix" style="text-align: left;">
                        <label class="l-check">
                        <input :checked="isSavePassword" @change="onSavePassword" type="checkbox"/><span style="margin-left:10px;">记住密码</span>
                        </label>
                    </div>
                    <div class="login-btn">
                        <el-button type="primary" @click="submitForm()">登录</el-button>
                    </div>
                </el-form> -->
            </div>
        </div>
    </div>
</template>


<script>
// import vueQr from "vue-qr";
// import getUserIP from "@/utils/tools";
import {mapGetters} from "vuex";
import {getCurrentDate} from "@/utils/time";

export default {
    components: {
        // vueQr
    },
    computed: {
        ...mapGetters(['unreadMessageList']),
    },
    data() {
        return {
            title:'',
            param: {
                // username: "admin",
                // password: "123123"
                username: "",
                password: "",
                ip:'',
                cityName:'',
             },
            
            
            rules: {
                username: [
                    { required: true, message: "请输入用户名", trigger: "blur" }
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" }
                ]
            },
            passwordType: 'password',
            capsTooltip: false,
            loading: false,
            showDialog: false,
            redirect: undefined,
            otherQuery: {},
            isSavePassword: false
        };
    },
    watch: {
    $route: {
      handler: function (route) {
        console.log('路由发生更新', route);
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
    created() {
        this.$store.commit("clearTags");
        this.title = this.$store.state.title;
    },
    mounted() {
        if (this.param.username === '') {
            this.$refs.username.focus()
        } else if (this.param.password === '') {
            this.$refs.password.focus()
        }
        this.isSavePassword = !!localStorage.getItem('savePassword');
        // console.log('isSavePassword', typeof this.isSavePassword, this.isSavePassword);
        // console.log('localStorage', localStorage.getItem('loginInfo')); 
        this.isSavePassword && (this.param = JSON.parse(localStorage.getItem('loginInfo')) || {
            username: '',
            password: ''
        })

        const s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = 'http://pv.sohu.com/cityjson?ie=utf-8&ver='+Math.random();
        //document.body.appendChild(s);
        // console.log(localStorage.getItem('Ip'),localStorage.getItem('cityname'))
    },

    methods: {
        onSavePassword({target: {checked}}) {
            this.isSavePassword = checked
            // console.log(this.isSavePassword);
        },
        // toFindPasswordPage() {
        //     this.$router.push({path: '/find-password', query: this.otherQuery})
        // },

        getOtherQuery(query) {
            return Object.keys(query).reduce((acc, cur) => {
                if (cur !== 'redirect') {
                    acc[cur] = query[cur]
                }
                return acc
            }, {})
        },

        async submitForm() {
			var Ip = '';
            var cityname = '';
            try {
                Ip = returnCitySN['cip'];
                cityname = returnCitySN['cname']
            } catch (e) {
            // 错误处理代码片段
            }
            localStorage.setItem('Ip', Ip)
            localStorage.setItem('cityName', cityname)

            this.param.ip = localStorage.getItem('Ip');
            this.param.cityName = localStorage.getItem('cityName');

            this.$refs.login.validate(async valid => {
                if (valid) {
                    this.loading = true
                    try {
                        // console.log('aa')
                        await this.$store.dispatch('user/login', this.param).then(async ()=>{
                            localStorage.setItem('loginInfo', JSON.stringify({
                                ...this.param,
                                userId: this.$store.state.user.userId,
                                roleId:this.$store.state.user.roleId,
                            }));
                            
                            
                            if (this.isSavePassword) {
                                localStorage.setItem('savePassword', this.isSavePassword);
                            } else {
                                localStorage.removeItem('savePassword');
                            }
                            // await store.dispatch('user/getUserCompany', {username, password});
                            // console.log('this.$store.state.user',this.$store.state.user)
                            localStorage.setItem("ms_username", this.param.username);
                            localStorage.setItem("ms_name", this.$store.state.user.name);
                            
                            await this.$store.dispatch('user/getUserMenu');
                            // console.log('aaa',this.otherQuery)
                            this.$router.push({path: '/bkground/projList', query: this.otherQuery})
                            this.loading = false
                        })

                        
                    } catch (e) {
                        console.log('e',e)
                        // Message({message: e, type: 'error', duration: 3 * 1000})
                        // Message({message: '账号或密码错误', type: 'error', duration: 3 * 1000})
                    } finally {
                        this.loading = false
                    }

                } else {
                    this.$message.error("请输入账号和密码");
                    return false;
                }
            });
        },

         getUserIP(onNewIP) {
             debugger;
            let MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
            let pc = new MyPeerConnection({
            iceServers: []
            });
            let noop = () => {
            };
            let localIPs = {};
            let ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
            let iterateIP = (ip) => {
            if (!localIPs[ip]) onNewIP(ip);
            localIPs[ip] = true;
            };
            pc.createDataChannel('');
            pc.createOffer().then((sdp) => {
            sdp.sdp.split('\n').forEach(function (line) {
                if (line.indexOf('candidate') < 0) return;
                line.match(ipRegex).forEach(iterateIP);
            });
            pc.setLocalDescription(sdp, noop, noop);
            }).catch((reason) => {
            });
            pc.onicecandidate = (ice) => {
            if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
            ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
            };
        },

        getUserIp2(){
            const os = require('os')
            let ip = ''
            try {
            const network = os.networkInterfaces()
            ip = network[Object.keys(network)[0]][1].address
            } catch (e) {
                ip = 'localhost';
            }
            return ip;
        },


    }
};
</script>

<style scoped>
.body {
    background-color: #f2f2f2;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.login-img {
    height: 100vh;
}

.login {
    border-radius: 14px;
    width: 600px;
    height: 440px;
    background-color: white;
    position: absolute;
    left: 51%;
    right: 0;
    top: 28%;
    bottom: 0;
    box-shadow: 0 2px 23px 0 #6c7bb02d;
}

.login-container {
    width: calc(100% - 80px);
    padding-top: 3.4rem;
    height: 80px;
    text-align: center;
    line-height: 12px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 2px solid #6c7bb02d;
}

.login-container-english {
    color: red;
    font-size: 15px;
    margin-top: 1.2rem;
}

.login-container-form {
    margin-top: 16px;
    text-align: center;
    margin-top: 48px;
}

.login-container-form-picture {
    position: absolute;
    left: 18%;
    width: 42px;
    height: 30px;
    border: 2px solid #b0bbca;
    padding: 4px;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    text-align: center;
    line-height: 30px;
    background-color: #f7f8f9;
}

.login-container-form-picture-icon {
    width: 14px;
    height: 14px;
}

.login-container-form-input {
    width: 56%;
    height: 36px;
    margin-bottom: 24px;
    border-radius: 4px;
    border: 2px solid #b0bbca;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    margin-left: 10%;
}

.login-container-form-remember {
    margin-left: -48%;
}

.login-button {
    width: calc(100% - 190px);
    line-height: 36px;
    background: #2989ff;
    border-color: #2989ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    margin-top: 24px;
    font-weight: 600;
    margin-left: 3%;
    cursor: pointer;
}

.login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    /* background-image: url(../assets/img/bg1.jpg); */
    background-size: 100%;
}
.ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 40px;
    margin-top:20px;
    /* color: #fff; */
    /* border-bottom: 1px solid #ddd; */
}
.ms-subtitle {
    width: 100%;
    line-height: 30px;
    text-align: center;
    font-size: 20px;
    /* color: #fff; */
    margin-top:20px;
    border-bottom: 1px solid #ddd;
}
.ms-login {
    position: absolute;
    left: 45%;
    top: 50%;
    width: 550px;    
    margin: -190px 0 0 -175px;
    border-radius: 5px;
    /* background: rgba(255, 255, 255, 0.3); */
    background-color: white;
    overflow: hidden;
}
.ms-content {
    padding: 30px 30px;
    margin-top:10px;
}
.login-btn {
    text-align: center;
    margin-top:20px;
}
.login-btn button {
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
}
.login-tips {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
}

.background1{
        background: url("../assets/img/zuozhan.jpg") no-repeat;
        background-position: center;
        height: 100%;
        width: 100%;
        background-size: cover;
        position: fixed;
    }

    .background2{
        background: url("../assets/img/zhihui.jpg") no-repeat;
        background-position: center;
        height: 100%;
        width: 100%;
        background-size: cover;
        position: fixed;
    }

    .background3{
        background: url("../assets/img/danyao.jpg") no-repeat;
        background-position: center;
        height: 100%;
        width: 100%;
        background-size: cover;
        position: fixed;
    }

</style>