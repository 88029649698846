// import {Message, MessageBox} from "element-ui";

const os = require('os')


export function getMultipleValue(resultArray, originArray) {
    if (!resultArray.length) {
        return '';
    }
    let tempNum = 0, tempStr = '';
    resultArray.forEach(item => tempNum += item);
    console.log(tempNum);
    tempStr = tempNum.toString();
    tempStr = ('000000' + tempStr).slice(-originArray.length);
    console.log('tempStr', tempStr);
    return tempStr;
}

// export function dealDeleteOperation({deletePromise}) {
//     return new Promise((resolve, reject) => {
//         MessageBox.confirm('即将执行删除操作, 是否继续?', '提示', {
//             confirmButtonText: '确定',
//             cancelButtonText: '取消',
//             type: 'warning'
//         }).then(async () => {
//             return deletePromise;
//         }).then(() => {
//             resolve();
//             Message.success('删除成功!');
//         });
//     })

// }


export function formatDate(params ) {
    let defalutParams = {
      date: new Date(),
      formatStr: 'yyyy-MM-dd HH:mm:ss'
    }
    params = { ...defalutParams, ...params };
    let date = params.date;
    let formatStr = params.formatStr;
    // 传入日期字符串 - 转成时间戳 - 转成标准时间
    let timeStamp = new Date(date).getTime();
    date = new Date(timeStamp);
    formatStr = formatStr.replace(new RegExp('yyyy'), `${date.getFullYear()}`);
    const month = date.getMonth() + 1;
    formatStr = formatStr.replace(new RegExp('MM'), `${month > 9 ? month : '0' + month}`);
    const day = date.getDate();
    formatStr = formatStr.replace(new RegExp('dd'), `${day > 9 ? day : '0' + day}`);
    const hour = date.getHours();
    formatStr = formatStr.replace(new RegExp('HH'), `${hour > 9 ? hour : '0' + hour}`);
    const min = date.getMinutes();
    formatStr = formatStr.replace(new RegExp('mm'), `${min > 9 ? min : '0' + min}`);
    const sec = date.getSeconds();
    formatStr = formatStr.replace(new RegExp('ss'), `${sec > 9 ? sec : '0' + sec}`);
    return formatStr;
  }

  export function getUserIp2(){
    let ip = ''
    try {
      const network = os.networkInterfaces()
      ip = network[Object.keys(network)[0]][1].address
    } catch (e) {
        ip = 'localhost';
    }
    return ip;
  }

  export function sortBykey(ary, key) {
    return ary.sort(function (a, b) {
       let x = a[key]
       let y = b[key]
       return ((x < y) ? -1 : (x > y) ? 1 : 0)
     })
 }

  
  export function getUserIP(onNewIP) {
    let MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    let pc = new MyPeerConnection({
      iceServers: []
    });
    let noop = () => {
    };
    let localIPs = {};
    let ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
    let iterateIP = (ip) => {
      if (!localIPs[ip]) onNewIP(ip);
      localIPs[ip] = true;
    };
    pc.createDataChannel('');
    pc.createOffer().then((sdp) => {
      sdp.sdp.split('\n').forEach(function (line) {
        if (line.indexOf('candidate') < 0) return;
        line.match(ipRegex).forEach(iterateIP);
      });
      pc.setLocalDescription(sdp, noop, noop);
    }).catch((reason) => {
    });
    pc.onicecandidate = (ice) => {
      if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
      ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
    };
  }

  export function swapItems(arr, index1, index2) {
    // var pre = []
    // var post = []
    // var item1 = {}
    // var item2 = {}
    // arr.forEach((ele,loopIndex) => {
    //   if (loopIndex < index1) {
    //     pre.push(ele)
    //   } else if (loopIndex = index1) {
    //     item1 = ele
    //   } else if (loopIndex = index2) {
    //     item2 = ele
    //   } else {
    //     post.push(ele)
    //   }
    // });
    // pre.push(item2)
    // post = post.unshift(item1)  // 在数组的最前面添加一个元素
    // return pre.concat(post)
    arr[index1] = arr.splice(index2, 1, arr[index1])[0]
    return arr
  }

  export function getPreItemWithIndex(arr, index) {
    var newArr = []
    arr.forEach((ele,loopIndex) => {
      if (loopIndex < index){
        ele.index = loopIndex + ''
        console.log('ele',ele)
        // if (ele.typeId !== 3 && ele.typeId !== 6 && ele.typeId !== 7){
          newArr.push(ele)
        // }        
      }
    })
    return newArr
  }

  export function _isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    console.log('aaa',navigator.userAgent)
    console.log('bbb',flag)
    if (flag){
      return true
    }else{
      return false
    }
    // return flag;
}

const S4 = function() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};
export function guid() {
  return (S4() + S4() +'-' +S4() +'-' +S4() +'-' +S4() +'-' +S4() +S4() +S4()
  );
}